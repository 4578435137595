import React from 'react';
import { useHistory } from 'react-router';
import { Peer } from '../../hooks/usePeers';
import { ROUTE_PATH } from '../../routes/route-paths';
import { Avatar, AvatarSkeleton, PhoneIcon } from '@kindlyhuman/component-library';
import { Clock } from '../../assets';

export const ConnectNowPeerTile: React.FC<{ peer: Peer }> = ({ peer }) => {
  const history = useHistory();
  return (
    <div
      key={peer.listener_role_id}
      className="bg-white rounded-[10px] flex gap-2 select-none cursor-pointer"
      onClick={() => {
        history.push(`${ROUTE_PATH.PEER_DETAILS}?listenerId=${peer.listener_role_id}`);
      }}
    >
      <Avatar variant="rounder" image={peer.profile_photo_url_square} className="m-3" />
      <div className="flex flex-col py-2 gap-2">
        <div data-testid={`peer-${peer.display_name}`} className="text-lg font-textaBlack antialiased leading-normal">
          {peer.display_name}
        </div>
        <div className="flex gap-2">
          <div className="rounded-full w-5 h-5 bg-[#2ee5da] flex justify-center items-center">
            <PhoneIcon color="black" width={11} />
          </div>
          <div className="text-sm font-texta antialiased leading-normal">Listener</div>
        </div>
        <div className="flex gap-2">
          <div className="rounded-full w-5 h-5 bg-[#2ee5da] flex justify-center items-center">
            <img src={Clock} alt="clock" />
          </div>
          <div className="text-sm font-texta antialiased leading-normal">Available Now</div>
        </div>
      </div>
    </div>
  );
};

export const ConnectNowPeerTileSkeleton: React.FC = () => {
  return (
    <div className="bg-gray-200 rounded-[10px] flex gap-2 animate-pulse">
      <AvatarSkeleton variant="rounder" className="m-3" />
      <div className="flex flex-col py-3 gap-2 w-full">
        <div className="h-6 bg-gray-300 rounded w-3/4"></div>
        <div className="flex gap-2">
          <div className="rounded-full w-5 h-5 bg-gray-300"></div>
          <div className="h-4 bg-gray-300 rounded w-1/4"></div>
        </div>
        <div className="flex gap-2">
          <div className="rounded-full w-5 h-5 bg-gray-300"></div>
          <div className="h-4 bg-gray-300 rounded w-1/4"></div>
        </div>
      </div>
    </div>
  );
};
