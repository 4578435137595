import React from 'react';
import TileContainer from '../../../components/mwa-3.5-redesign/tile-container';

import { Experience } from '../../../hooks/useExperiences';
import { HorizontalScrollSection } from '../../../components/common/horizontal-scroll';
import { ExperienceTile, ExperienceTileSkeleton } from '../../../components/mwa-3.5-redesign/experience-tile';

interface ExperienceRecommendationsProps {
  recommendedExperiences: Experience[] | undefined;
  isLoading: boolean;
  setSearchFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleScheduleModalOpen: (experience: Experience) => void;
}

const ExperienceRecommendations: React.FC<ExperienceRecommendationsProps> = ({
  recommendedExperiences,
  isLoading,
  setSearchFilterOpen,
  handleScheduleModalOpen,
}) => {
  return (
    <>
      <TileContainer
        title="You're not alone"
        subtitle="Say hello to Peers with similar life experiences"
        childrenTiles={
          <HorizontalScrollSection
            className=""
            showNavigationButton
            isLoading={isLoading}
            children={recommendedExperiences?.map((experience: Experience) => (
              <ExperienceTile
                key={experience.id}
                experience={experience}
                handleScheduleModalOpen={handleScheduleModalOpen}
              />
            ))}
          />
        }
        skeletonTiles={
          <HorizontalScrollSection
            className=""
            showNavigationButton
            isLoading={isLoading}
            children={[...Array(6).keys()].map((int) => (
              <ExperienceTileSkeleton key={int} />
            ))}
          />
        }
        isLoading={isLoading}
        bgColor=""
        horizontal
        searchContainer
        setSearchFilterOpen={setSearchFilterOpen}
      />
    </>
  );
};

export default ExperienceRecommendations;
