import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { PostCallFormRef } from '../../../components/common/PostCallForm';
import { ActiveCall, CallRequest, useAcceptBackupConnectionMutation, useActiveCalls } from '../../../hooks/useCalls';
import { PostCallListItem, usePostCalls } from '../../../hooks/usePostCall';
import { useUser } from '../../../hooks/useUser';

import { CallCardWrapper } from '../../../components/mwa-3.5-redesign/call-card';
import { ROUTE_PATH } from '../../../routes/route-paths';
import { SchedulingModal } from '../../../components/scheduling-modal/scheduling-modal';
import { PostCallModal } from '../../../components/PostCallModal';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';

interface CallConnectionsProps {
  callRequests: CallRequest[] | undefined;
  incompletePostCalls: PostCallListItem[] | undefined;
  activeCalls: ActiveCall[] | undefined;
}

export interface ScheduleModalData {
  isNow: boolean;
  listenerId: number;
  originalRequestId: number;
}

const CallConnections: React.FC<CallConnectionsProps> = ({ callRequests, incompletePostCalls, activeCalls }) => {
  const history = useHistory();
  const dfMdMedia = useMediaQuery('md');
  const callInEarlyFlag = useFeatureFlag('CALL_IN_EARLY');

  const [scheduleModal, setScheduleModal] = useState<ScheduleModalData>();
  const [postCallIdForPostCallModal, setPostCallIdForPostCallModal] = useState<number>();

  const acceptBackupConnection = useAcceptBackupConnectionMutation();

  const showRescheduleModal = (callData: CallRequest) => {
    setScheduleModal({
      isNow: callData.connections[0].listener_role.available_now,
      listenerId: callData.connections[0].listener_role.id,
      originalRequestId: callData.id,
    });
  };

  const now = new Date();
  const tenMinutesFromNow = new Date(now.getTime() + 10 * 60000);
  const fiveMinutesFromNow = new Date(now.getTime() + 5 * 60000);

  const postCallModalForm = useRef<PostCallFormRef>(null);

  const pendingRequests = callRequests?.filter(
    (request) => request.connections[0].status === 'matched' || request.status === 'backup_pending',
  );

  const unavailableRequests = callRequests
    ?.filter((request) => request.connections[0].status === 'canceled' || request.connections[0].status === 'passed')
    .filter((request) => pendingRequests?.includes(request) === false);

  let backupRequests = callRequests?.filter(
    (request) => request.connections[0].status === 'offered' && request.is_backup_request === true,
  );

  const upcomingCalls = callRequests?.filter(
    (request) =>
      ['accepted', 'offered'].includes(request.connections[0].status) &&
      request.is_backup_request === false &&
      request.connections[0].scheduled_at &&
      new Date(request.connections[0].scheduled_at) <=
        (callInEarlyFlag.enabled ? tenMinutesFromNow : fiveMinutesFromNow),
  );

  const scheduledCalls = callRequests?.filter(
    (request) =>
      ['accepted', 'offered'].includes(request.connections[0].status) &&
      request.is_backup_request === false &&
      request.connections[0].scheduled_at &&
      new Date(request.connections[0].scheduled_at) >
        (callInEarlyFlag.enabled ? tenMinutesFromNow : fiveMinutesFromNow),
  );

  const inProgressCalls = activeCalls?.filter((call) => call.state.type === 'call_in_progress');

  const initializingCalls = activeCalls?.filter((call) => call.state.type !== 'call_in_progress');

  useEffect(() => {
    if (postCallIdForPostCallModal && !dfMdMedia) {
      sessionStorage.setItem(
        `postCallForm-${postCallIdForPostCallModal}`,
        JSON.stringify({
          step: postCallModalForm?.current?.step,
          ...postCallModalForm?.current?.getValues(),
        }),
      );
      history.push(`${ROUTE_PATH.POSTCALLSEQUENCE}?postCallId=${postCallIdForPostCallModal}`);
    }
  }, [postCallIdForPostCallModal, dfMdMedia, history]);

  return (
    <>
      <div className="flex flex-col gap-4">
        {inProgressCalls?.map((activeCall) => (
          <CallCardWrapper
            variant="active"
            activeCall={activeCall}
            buttonClick={() => window.open(`tel:${process.env.REACT_APP_TWILIO_PHONE_NUMBER}`, '_blank')}
          />
        ))}
        {initializingCalls?.map((initializingCall) => (
          <CallCardWrapper
            variant="upcoming"
            activeCall={initializingCall}
            buttonClick={() => window.open(`tel:${process.env.REACT_APP_TWILIO_PHONE_NUMBER}`, '_blank')}
            fiveMinutesOut
            isNowCall={initializingCall.request.connections[0].scheduled_at === null}
          />
        ))}
        {backupRequests?.map((backupRequest) => (
          <CallCardWrapper
            variant="backup"
            callRequest={backupRequest}
            buttonClick={() => acceptBackupConnection.mutate(backupRequest.connections[0].id)}
          />
        ))}
        {upcomingCalls?.map((upcomingCall) => (
          <CallCardWrapper
            variant="upcoming"
            callRequest={upcomingCall}
            buttonClick={() => window.open(`tel:${process.env.REACT_APP_TWILIO_PHONE_NUMBER}`, '_blank')}
          />
        ))}
        {pendingRequests?.map((pendingCall) => <CallCardWrapper variant="pending" callRequest={pendingCall} />)}
        {incompletePostCalls?.map((completedCall) => (
          <CallCardWrapper
            variant="completed"
            postCall={completedCall}
            buttonClick={() => {
              if (dfMdMedia) {
                console.log('open modal');
                setPostCallIdForPostCallModal(completedCall.id);
              } else {
                console.log('redirect');
                history.push(`${ROUTE_PATH.POSTCALLSEQUENCE}?postCallId=${completedCall.id}`);
              }
            }}
          />
        ))}
        {unavailableRequests?.map((unavailableRequest) => (
          <CallCardWrapper
            variant="unavailable"
            callRequest={unavailableRequest}
            buttonClick={() => showRescheduleModal(unavailableRequest)}
          />
        ))}
        {/* We currently don't have a good way to display missed calls in a dismissable fashion,
        so will come in an upcoming ticket, but the variant is created here */}
        {/* <CallCardWrapper variant="missed" /> */}
        {scheduledCalls?.map((scheduledCall) => (
          <CallCardWrapper
            variant="scheduled"
            callRequest={scheduledCall}
            buttonClick={() => showRescheduleModal(scheduledCall)}
          />
        ))}
      </div>
      {scheduleModal && (
        <SchedulingModal
          open={!!scheduleModal}
          isNow={scheduleModal.isNow}
          onExit={() => {
            setScheduleModal(undefined);
          }}
          originalRequestId={scheduleModal.originalRequestId}
          listenerId={scheduleModal.listenerId!}
        />
      )}
      {postCallIdForPostCallModal && (
        <PostCallModal
          formRef={postCallModalForm}
          open={!!postCallIdForPostCallModal}
          onClose={() => setPostCallIdForPostCallModal(undefined)}
          postCallId={postCallIdForPostCallModal}
        />
      )}
    </>
  );
};

export default CallConnections;
