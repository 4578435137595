import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import '@kindlyhuman/component-library/dist/style.css';
import { analytics } from '@kindlyhuman/component-library';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ROUTE_PATH } from './routes/route-paths';
import { AuthProvider } from './contexts/auth-context';
import { StickyStateKeys } from './hooks/useStickyState';
import { SettingsModalProvider } from './contexts/settings-modal';

if (process.env.REACT_APP_MWA_GTAG_ID) {
  analytics.init(process.env.REACT_APP_MWA_GTAG_ID);
} else {
  console.error('Could not initialize analytics', process.env.REACT_APP_MWA_GTAG_ID);
}
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      onError: (err: any) => {
        if (err.response?.status === 403) {
          localStorage.removeItem(StickyStateKeys.AuthorizationToken);
          window.location.href = ROUTE_PATH.LOGIN;
        }
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <BrowserRouter>
            <AuthProvider>
              <SettingsModalProvider>
                <App />
              </SettingsModalProvider>
            </AuthProvider>
          </BrowserRouter>
        </LocalizationProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
