import React from 'react';
import { ROUTE_PATH } from '../../../routes/route-paths';

import TileContainer from '../../../components/mwa-3.5-redesign/tile-container';
import { AvailableListenersResponse } from '../../../hooks/usePeers';
import { UseQueryResult } from '@tanstack/react-query';
import {
  ConnectNowPeerTile,
  ConnectNowPeerTileSkeleton,
} from '../../../components/mwa-3.5-redesign/connect-now-peer-tile';

interface ConnectNowProps {
  availableListeners: UseQueryResult<AvailableListenersResponse, unknown>;
}

const ConnectNow: React.FC<ConnectNowProps> = ({ availableListeners }) => {
  return (
    <TileContainer
      title={'Connect Now'}
      subtitle={
        availableListeners?.data?.count && availableListeners.data.count > 0
          ? 'Listeners available to connect right now'
          : 'No listeners available to connect right now'
      }
      isLoading={availableListeners.isLoading}
      childrenTiles={
        <>
          {availableListeners?.data?.data?.map((peer) => (
            <ConnectNowPeerTile key={peer.listener_role_id} peer={peer} />
          ))}
        </>
      }
      skeletonTiles={[1, 2].map((int) => (
        <ConnectNowPeerTileSkeleton key={int} />
      ))}
      bgColor={'bg-[#2ee5da] bg-opacity-40'}
      dataTestId={'connect-now-container'}
      redirectText={
        availableListeners?.data?.count ? 'See all available Listeners »' : 'See Listeners available to connect later »'
      }
      redirectLink={ROUTE_PATH.AVAILABLE_LISTENERS}
    />
  );
};

export default ConnectNow;
