import React from 'react';
import TileContainer from '../../../components/mwa-3.5-redesign/tile-container';

import { Resource } from '../../../hooks/useResources';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { HorizontalScrollSection } from '../../../components/common/horizontal-scroll';
import { ResourceTile, ResourceTileSkeleton } from '../../../components/mwa-3.5-redesign/resource-tile';

interface ResourceRecommendationsProps {
  recommendedResources: Resource[] | undefined;
  isLoading: boolean;
}

const ResourceRecommendations: React.FC<ResourceRecommendationsProps> = ({ recommendedResources, isLoading }) => {
  const dfMdMedia = useMediaQuery('md');
  return (
    <TileContainer
      title="Just For You"
      subtitle="Program and Resource Recommendations"
      childrenTiles={
        <HorizontalScrollSection
          className=""
          showNavigationButton
          isLoading={isLoading}
          children={recommendedResources
            ?.slice(0, 20)
            .map((resource: Resource) => (
              <ResourceTile
                key={resource.id}
                id={resource.id}
                image={resource.image_url}
                resourceName={resource.name}
                caption={resource.description}
                tagNames={resource.tag_names}
                horizontal
              />
            ))}
        />
      }
      skeletonTiles={
        <HorizontalScrollSection
          className=""
          showNavigationButton
          isLoading={isLoading}
          children={[...Array(6).keys()].map((int) => (
            <ResourceTileSkeleton key={int} horizontal />
          ))}
        />
      }
      isLoading={isLoading}
      bgColor=""
      horizontal
      searchContainer
    />
  );
};

export default ResourceRecommendations;
