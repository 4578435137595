import { HTMLAttributes } from 'react';

import { Avatar, Button, HeartIcon, PhoneIcon } from '@kindlyhuman/component-library';

import { PeerDetail } from '../../../hooks/usePeers';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { useHistory } from 'react-router';
import { ROUTE_PATH } from '../../../routes/route-paths';
import { Clock } from '../../../assets';
import { TagPill } from '../../../components/mwa-3.5-redesign/tag-pill';
import { FollowPill } from '../../../components/mwa-3.5-redesign/follow-pill';

interface PeerDetailCardProps extends HTMLAttributes<HTMLDivElement> {
  peerDetails?: PeerDetail;
  handleFollowing: () => void;
  setScheduleModal: (value: boolean) => void;
  setIsNow: (value: boolean) => void;
}

const PeerDetailCard: React.FunctionComponent<PeerDetailCardProps> = ({
  peerDetails,
  handleFollowing,
  setScheduleModal,
  setIsNow,
}): JSX.Element => {
  const dfMdMedia = useMediaQuery('md');
  const history = useHistory();

  return (
    <>
      {!dfMdMedia ? (
        <div
          data-TestId={`Peer #${peerDetails?.id}`}
          className={`h-[17rem] bg-[#e5efff] flex flex-col gap-6 p-5 select-none`}
        >
          <div className="flex gap-6">
            <Avatar variant="rounderLarge" image={peerDetails?.profile_photo_square_file_url!} className="" />
            <div className="flex flex-col justify-between">
              <div className="flex flex-col gap-2">
                <div
                  data-testid={`peer-${peerDetails?.user.display_name}`}
                  className="text-xl font-extrabold font-manrope text-[#240089]"
                >
                  {peerDetails?.user.display_name}
                </div>
                {peerDetails?.is_active && (
                  <div className="flex gap-2">
                    <div className="rounded-full w-6 h-6 bg-[#2ee5da] flex justify-center items-center">
                      <PhoneIcon color="black" width={14} />
                    </div>
                    <div className="text-sm font-texta antialiased leading-normal">Listener</div>
                  </div>
                )}
                {peerDetails?.available_now && (
                  <div className="flex gap-2">
                    <div className="rounded-full w-6 h-6 bg-[#2ee5da] flex justify-center items-center">
                      <img src={Clock} alt="clock" />
                    </div>
                    <div className="text-sm font-texta antialiased leading-normal">Available Now</div>
                  </div>
                )}
              </div>
              <FollowPill isFollowing={Boolean(peerDetails?.favorite_id)} handleFollowing={() => handleFollowing()} />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col gap-2">
              <div className="flex gap-2 flex-wrap overflow-hidden h-12">
                {peerDetails?.background_traits.map((tag: string) => <TagPill name={tag} />)}
              </div>
            </div>
            <div className="flex">
              {peerDetails?.is_active && (
                <Button
                  variant="secondary"
                  className="w-full text-[#2ee5da] bg-[#081d40] hover:bg-[#081d40] hover:bg-opacity-70"
                  onClick={() => {
                    setScheduleModal(true);
                    setIsNow(Boolean(peerDetails?.available_now));
                  }}
                >
                  {peerDetails?.available_now ? 'Connect Now' : 'Schedule a Call'}
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div data-TestId={`Peer #${peerDetails?.id}`} className={`bg-[#e5efff] flex flex-col py-5 gap-6 select-none`}>
          <div className="flex max-w-7xl mx-auto w-full py-4 px-6 justify-between">
            <div className="flex gap-6">
              <Avatar variant="huge" image={peerDetails?.profile_photo_square_file_url!} className="" />
              <div className="flex flex-col mt-8 justify-between">
                <div className="flex flex-col gap-2">
                  <div
                    data-testid={`peer-${peerDetails?.user.display_name}`}
                    className="text-3xl font-extrabold font-manrope text-[#240089]"
                  >
                    {peerDetails?.user.display_name}
                  </div>
                  {peerDetails?.is_active && (
                    <div className="flex gap-2">
                      <div className="rounded-full w-6 h-6 bg-[#2ee5da] flex justify-center items-center">
                        <PhoneIcon color="black" width={14} />
                      </div>
                      <div className="text-sm font-texta antialiased leading-normal">Listener</div>
                    </div>
                  )}
                  {peerDetails?.available_now && (
                    <div className="flex gap-2">
                      <div className="rounded-full w-6 h-6 bg-[#2ee5da] flex justify-center items-center">
                        <img src={Clock} alt="clock" />
                      </div>
                      <div className="text-sm font-texta antialiased leading-normal">Available Now</div>
                    </div>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2 flex-wrap overflow-hidden">
                    {peerDetails?.background_traits.map((tag: string) => (
                      <div className="text-nowrap h-5 text-xs font-texta antialiased leading-normal bg-white px-2 rounded-md">
                        {tag}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-8 justify-between  w-1/3">
              <div className="flex justify-end">
                <FollowPill isFollowing={Boolean(peerDetails?.favorite_id)} handleFollowing={() => handleFollowing()} />
              </div>
              {peerDetails?.is_active && (
                <div className="flex">
                  <Button
                    variant="secondary"
                    className="w-full text-[#2ee5da] bg-[#081d40] hover:bg-[#081d40] hover:bg-opacity-70"
                    onClick={() => {
                      setScheduleModal(true);
                      setIsNow(Boolean(peerDetails?.available_now));
                    }}
                  >
                    {peerDetails?.available_now ? 'Connect Now' : 'Schedule a Call'}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PeerDetailCard;
