import { useEffect, useRef, useState } from 'react';
import { Tag } from '../../../components/common/tag';
import { PeerCardExperience } from './experiences-section';
import { EventTypes, PauseIcon, PlayIcon, analytics } from '@kindlyhuman/component-library';
import { useListenerAudioPlayMutation, useListenerAudioPlaybackMutation } from '../../../hooks/useListenerAudio';
import { TranscriptionModal } from './transcription-modal';
import { ThumbsDownIcon, ThumbsUpIcon } from '../../../assets';
import { useRateExperience } from '../../../hooks/useExperiences';

export interface ExperienceCardProps {
  experience: PeerCardExperience;
}

export const ExperienceCard = ({ experience }: ExperienceCardProps) => {
  const [paused, setPaused] = useState(true);
  const playMutation = useListenerAudioPlayMutation();
  const playBackMutation = useListenerAudioPlaybackMutation();
  const [currentPlaybackId, setCurrentPlaybackId] = useState<number>();
  const rateExperienceMutation = useRateExperience();
  const audioRef = useRef<HTMLAudioElement>(null);

  const audioStopAll = () => {
    document.querySelectorAll('audio').forEach((el) => {
      el.pause();
      el.currentTime = 0;
    });
  };

  const onTimeUpdate = (e: React.SyntheticEvent<HTMLAudioElement>) => {
    if (e.currentTarget.currentTime >= e.currentTarget.duration) {
      setPaused(true);
    }

    if (!currentPlaybackId) {
      console.error('currentPlaybackId is not set for experience id:', experience.id);
      return;
    }

    const percent = Math.round((e.currentTarget.currentTime / experience.duration) * 100);
    // if the percent is divisible by 10
    if (percent % 10 === 0) {
      playBackMutation.mutate({
        audioId: currentPlaybackId,
        percentCompleted: percent,
      });
    }
  };

  const onPlay = () => {
    audioStopAll();
    playMutation.mutate(
      {
        audioId: experience.id,
        listenerId: experience.listener_role_id,
      },
      {
        onSuccess: (listenerAudioPlayback) => {
          setCurrentPlaybackId(listenerAudioPlayback.id);
        },
      },
    );
    audioRef.current?.play();
  };

  const onPause = () => {
    audioRef.current?.pause();
    setPaused(true);
  };

  // we need to rerender the screen when the state of the audio changes, which this manages.
  useEffect(() => {
    if (!audioRef.current) {
      return;
    }
    setPaused(audioRef.current?.paused);
  }, [audioRef.current?.paused]);

  const formatTimestamp = (duration: number) => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return `${minutes.toString().padStart(2, '0')}:${Math.round(seconds).toString().padStart(2, '0')}`;
  };

  const handleRatingClick = (newRating: number) => {
    if (experience.rating === newRating) newRating = 0;

    rateExperienceMutation.mutate({
      audioId: experience.id,
      listenerId: experience.listener_role_id,
      rating: newRating,
    });
  };

  return (
    <>
      <div id="experience-card" className="w-[336px] font-['Manrope'] bg-white rounded-[10px] p-4">
        <div id="tag-container" className="pb-4 flex gap-x-2 gap-y-2 flex-wrap">
          {experience.tag_names?.map((tag) => <Tag key={tag} tag={tag} />)}
        </div>
        <div className="mb-4 w-[301px] text-[#222833] text-base  leading-[21px] line-clamp-5 overflow-hidden text-wrap truncate">
          {experience.admin_summary || experience.excerpt}
        </div>
        <div className="flex flex-nowrap justify-between items-center">
          <div className="flex gap-x-2 flex-nowrap items-center">
            <audio ref={audioRef} onTimeUpdate={onTimeUpdate} src={experience.audio_url} />
            <button
              className="bg-[#afff54] cursor-pointer rounded-full w-10 h-10 flex justify-center items-center"
              data-testid="play-experience"
              onClick={() => {
                analytics.trackEvent(EventTypes.PLAY_EXPERIENCE, experience.id);
                paused ? onPlay() : onPause();
              }}
            >
              {paused ? <PlayIcon className="bg-[#afff54]" /> : <PauseIcon className="bg-[#afff54]" />}
            </button>
            <span className="text-center text-[#666666] text-base font-normal leading-normal">
              {formatTimestamp(experience.duration)}
            </span>
          </div>
          {/* todo pdb: hover style */}
          <div className="flex cursor-pointer gap-x-2">
            <ThumbsUpIcon onClick={() => handleRatingClick(1)} isFill={experience.rating > 0} />
            <ThumbsDownIcon onClick={() => handleRatingClick(-1)} isFill={experience.rating < 0} />
          </div>
        </div>
      </div>
    </>
  );
};
