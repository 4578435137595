import React, { useEffect, createRef } from 'react';

import { PlayIcon } from '@kindlyhuman/component-library';

import { MyProgramDefaultPng } from '../../../assets';

import { ExitIcon } from '../../../components/common/svgs';

type WelcomeVideoProps = {
  videoSrc?: string;
  imageSrc?: string;
  clientLogo?: string;
  handleCloseVideo?: () => void;
};

let elem: any;

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
  }
}

// Home Welcome video
const WelcomeVideo: React.FunctionComponent<WelcomeVideoProps> = ({
  videoSrc,
  imageSrc,
  clientLogo,
  handleCloseVideo,
}): JSX.Element => {
  const [showVideo, setShowVideo] = React.useState(false);
  const refVideo: any = createRef();
  const box: any = document.getElementById('video-full-screen');
  const playVideo = () => {
    elem = document.getElementById('myvideo');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      if (!box.hasChildNodes()) {
        box.appendChild(elem);
      }
      box.style.display = 'block';
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.webkitEnterFullscreen) {
      elem.webkitEnterFullscreen();
    }
    elem.play();
  };

  useEffect(() => {
    if (document.addEventListener) {
      document.addEventListener('fullscreenchange', exitHandler, false);
      document.addEventListener('mozfullscreenchange', exitHandler, false);
      document.addEventListener('MSFullscreenChange', exitHandler, false);
      document.addEventListener('webkitfullscreenchange', exitHandler, false);
    }

    return () => {
      document.removeEventListener('fullscreenchange', () => {});
      document.removeEventListener('mozfullscreenchange', () => {});
      document.removeEventListener('MSFullscreenChange', () => {});
      document.removeEventListener('webkitfullscreenchange', () => {});
      if (box.hasChildNodes()) {
        box.removeChild(box.children[0]);
      }
    };
  }, []); // eslint-disable-line

  const exitHandler = () => {
    if (!document['fullscreenElement']) {
      if (
        //@ts-ignore
        !document['webkitIsFullScreen'] &&
        //@ts-ignore
        !document['mozFullScreen'] &&
        !document['msFullscreenElement']
      ) {
        // Run code on exit
        box.style.display = 'none';
        if (elem) elem.pause();
        setShowVideo(false);
      }
    }
  };

  const videoTimeUpdate = () => {
    const currentTimeUpdate = refVideo.current.currentTime;
    const duration = refVideo.current.duration;
    if (currentTimeUpdate === duration) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
      localStorage.setItem('isVideoDisplay', false.toString());
      handleCloseVideo && handleCloseVideo();
    }
  };

  return (
    <div className="h-full w-90 bg-[#e6e6e6] rounded-[10px] flex flex-col">
      <div className="flex justify-between px-3 pt-4">
        <div className="text-2xl font-textaBlack leading-normal">Welcome!</div>
        <ExitIcon
          data-testid="video-card-exit-icon"
          width={24}
          height={24}
          color={'#000'}
          className="cursor-pointer"
          onClick={handleCloseVideo}
        />
      </div>
      <div className="relative flex-1 flex justify-center items-center">
        <div
          className="w-[93%] aspect-w-16 aspect-h-9 relative mt-1.5 mb-2.5"
          onClick={() => {
            setShowVideo(true);
            playVideo();
          }}
        >
          <img
            className="w-full h-full rounded-[10px] object-cover"
            src={imageSrc ? imageSrc : MyProgramDefaultPng}
            alt="play"
          />
          <div className="absolute left-4 bottom-4 flex justify-center items-center">
            <div className="w-9 h-9 bg-white rounded-full flex justify-center items-center cursor-pointer">
              <PlayIcon className="w-3.5 h-3.5" />
            </div>
          </div>
          <div className="absolute right-4 bottom-4 flex justify-center items-center">
            <div className="w-16 h-9 flex justify-center items-center">
              <img src={clientLogo} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className={`h-52 bg-blue relative rounded-md ${!showVideo && 'hidden'}`}>
        <video
          className="absolute h-52 w-full rounded-md"
          id="myvideo"
          onClick={playVideo}
          role="button"
          playsInline
          ref={refVideo}
          onTimeUpdate={videoTimeUpdate}
        >
          <source src={videoSrc} />
        </video>
      </div>
    </div>
  );
};
export default WelcomeVideo;
