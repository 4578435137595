import { Input, SpinnerIcon, ExitIcon } from '@kindlyhuman/component-library';
import { SearchIcon } from '../../assets';
import { useEffect, useRef, useState } from 'react';
import { SearchFilterOptions } from '../../hooks/useExperiences';

interface SearchInputProps {
  query?: string;
  search: (value: string) => void;
  isLoading: boolean;
  searchFilterOpen: boolean;
  setSearchFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchFilters: React.Dispatch<React.SetStateAction<any>>;
}

export const SearchInput = ({ query, search, isLoading, setSearchFilters }: SearchInputProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    if (query && ref.current) {
      ref.current.value = query;
    }
  }, [query]);

  return (
    <div className="flex flex-col grow no-wrap gap-2 items-start w-full">
      <div className="md:max-w-2xl w-full text-gray-800 text-2xl font-bold font-textaBlack leading-normal">
        Search for Support
      </div>
      <div className="w-full flex no-wrap justify-center items-center gap-2">
        <Input
          onKeyUp={(event) => {
            // Number 13 is the "Enter" key on the keyboard
            if (event.keyCode === 13) {
              // Cancel the default action, if needed
              event.preventDefault();
              ref.current && search(ref.current?.value);
            }
          }}
          className="w-full "
          ref={ref}
          type="search"
          placeholder="How can we help you today?"
          onIconClick={() => {}}
          onChange={handleInputChange}
          s4s
        />
        {isLoading ? (
          <button onClick={() => ref.current && search(ref.current?.value)} className="border-2 rounded-lg">
            <SpinnerIcon className="cursor-pointer h-8 w-8 p-0" />
          </button>
        ) : (
          <button
            onClick={() => ref.current && search(ref.current?.value)}
            className={`border-2 p-2 bg-[#240089] ${inputValue === query && 'bg-opacity-50'} rounded-lg`}
            disabled={inputValue !== '' && inputValue === query}
          >
            <img src={SearchIcon} alt="Search Icon" className="w-6" />
          </button>
        )}
        {query && (
          <button
            onClick={() => {
              ref.current && (ref.current.value = '');
              search('');
              setSearchFilters((prev: SearchFilterOptions) => ({
                ...prev,
                supportQuery: '',
              }));
            }}
            className="border-2 p-2 bg-[#240089] rounded-lg"
          >
            <ExitIcon />
          </button>
        )}
      </div>
    </div>
  );
};
