import { useMemo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useHistory } from 'react-router-dom';

import {
  BookIcon,
  CommentIcon,
  CreditCardIcon,
  EmailIcon,
  MobileHeader,
  MobileHeaderProps,
  InfoIcon,
  LogoutIcon,
  UserIcon,
} from '@kindlyhuman/component-library';

import { ROUTE_PATH } from '../../routes/route-paths';

import { LogoutModal } from '../LogoutModal';
import { useLockBody } from '../../hooks/useLockBody';
import { useUser } from '../../hooks/useUser';
import { useHasMyPrograms } from '../../hooks/useResources';

export interface MobileMenuHeaderProps extends Omit<MobileHeaderProps, 'menuItems'> {}

export const MobileMenuHeader = ({ className, ...props }: MobileMenuHeaderProps) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isLogoutOpen, setLogoutOpen] = useState(false);
  useLockBody(isMenuOpen);

  const headerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

  const navigate = useHistory();

  const { data: user } = useUser();

  const hasMyPrograms = useHasMyPrograms();

  const poweredByHeaderEnabled = user?.caller_role?.active_subscription?.package?.client?.powered_by_header_enabled;
  const poweredByHeader = user?.caller_role?.active_subscription?.package?.client?.powered_by_header_file_url;

  const handleOpenStateStatus = (open: boolean) => {
    setMenuOpen(open);
  };

  const mobileMenuItems = useMemo(() => {
    const items = [
      {
        text: 'My Connections',
        icon: <CommentIcon />,
        href: ROUTE_PATH.MY_CONNECTIONS,
      },
      {
        text: 'Account Settings',
        icon: <UserIcon />,
        href: ROUTE_PATH.ACCOUNT_SETTINGS,
      },
      {
        text: 'Membership Management',
        icon: <CreditCardIcon />,
        href: ROUTE_PATH.MEMBERSHIP_MANAGEMENT,
      },
      {
        text: 'About Us',
        icon: <InfoIcon />,
        href: ROUTE_PATH.ABOUT,
      },
      {
        text: 'Support',
        icon: <EmailIcon />,
        href: ROUTE_PATH.SUPPORT,
      },
      {
        text: 'Log Out',
        icon: <LogoutIcon />,
        onClick: () => {
          setLogoutOpen(true);
        },
      },
    ];
    if (hasMyPrograms) {
      items.splice(1, 0, {
        text: 'My Programs',
        icon: <BookIcon />,
        href: ROUTE_PATH.RESOURCES,
      });
    }
    return items;
  }, [hasMyPrograms]);

  return (
    <>
      <div
        className={
          isMenuOpen ? 'fixed top-0 inset-0 bg-gray-600  z-100 bg-opacity-50 h-full w-screen overflow-y-scroll' : ''
        }
      >
        <div className={isMenuOpen ? 'absolute top-0 w-full' : ''}>
          <MobileHeader
            headerRef={headerRef}
            open={isMenuOpen}
            className={twMerge('md:hidden', className)}
            getOpenStatus={handleOpenStateStatus}
            menuItems={props.onBack ? undefined : mobileMenuItems}
            goHome={() =>
              navigate.push({
                pathname: ROUTE_PATH.HOME,
              })
            }
            poweredByHeader={poweredByHeaderEnabled ? poweredByHeader : undefined}
            {...props}
          />
        </div>
        <LogoutModal dataTestId="mobile-logout-dialog" open={isLogoutOpen} onClose={() => setLogoutOpen(false)} />
      </div>
    </>
  );
};
