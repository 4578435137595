import {
  Avatar,
  Button,
  Dialog,
  EventTypes,
  ExitIcon,
  PauseIcon,
  PhoneIcon,
  PlayIcon,
  analytics,
} from '@kindlyhuman/component-library';
import { Experience, useRateExperience } from '../../../hooks/useExperiences';
import React, { useEffect, useState } from 'react';
import { useListenerAudioPlayMutation, useListenerAudioPlaybackMutation } from '../../../hooks/useListenerAudio';
import { ROUTE_PATH } from '../../../routes/route-paths';
import { Link } from 'react-router-dom';
import { ThumbsDownIcon, ThumbsUpIcon } from '../../../assets';

export interface ExperienceModalProps {
  experience: Experience;
  open: boolean;
  onClose: () => void;
  handleScheduleModalOpen: (experience: Experience) => void;
}

export const ExperienceModal = ({ experience, open, onClose, handleScheduleModalOpen }: ExperienceModalProps) => {
  const audioRef = React.useRef<HTMLAudioElement>(null);
  const [paused, setPaused] = useState(true);
  const [percent, setPercent] = React.useState('0');
  const playMutation = useListenerAudioPlayMutation();
  const playBackMutation = useListenerAudioPlaybackMutation();
  const [currentPlaybackId, setCurrentPlaybackId] = useState<number>();
  const rateExperienceMutation = useRateExperience();

  const close = () => {
    if (audioRef.current) {
      audioRef.current?.pause();
      audioRef.current.currentTime = 0;
    }
    setPaused(true);
    onClose();
  };

  const onTimeUpdate = (e: React.SyntheticEvent<HTMLAudioElement>) => {
    if (e.currentTarget.currentTime >= e.currentTarget.duration) {
      setPaused(true);
    }

    if (!currentPlaybackId) {
      console.error('currentPlaybackId is not set for experience id:', experience.id);
      return;
    }

    const percent = Math.round((e.currentTarget.currentTime / experience.duration) * 100);
    // if the percent is divisible by 10
    if (percent % 10 === 0) {
      playBackMutation.mutate({
        audioId: currentPlaybackId,
        percentCompleted: percent,
      });
    }
  };

  const onPlay = () => {
    playMutation.mutate(
      {
        audioId: experience.id,
        listenerId: experience.listener_role_id,
        // tagIds: experience.topic_tags.map((tag) => tag.id),
      },
      {
        onSuccess: (listenerAudioPlayback) => {
          setCurrentPlaybackId(listenerAudioPlayback.id);
        },
      },
    );
    audioRef.current?.play();
  };

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }

    const node = audioRef.current;

    const timingEvent = (event: Event) => {
      const element = event.target as HTMLAudioElement;
      setPercent(((element.currentTime / element.duration) * 100).toPrecision(3));
    };

    node.addEventListener('timeupdate', timingEvent);

    return () => {
      node.removeEventListener('timeupdate', timingEvent);
    };
  }, []);

  // we need to rerender the screen when the state of the audio changes, which this manages.
  useEffect(() => {
    if (!audioRef.current) {
      return;
    }
    setPaused(audioRef.current?.paused);
  }, [audioRef.current?.paused]);

  const handleRatingClick = (newRating: number) => {
    if (experience.rating === newRating) newRating = 0;

    rateExperienceMutation.mutate({
      audioId: experience.id,
      listenerId: experience.listener_role_id,
      rating: newRating,
    });
  };

  return (
    <Dialog
      className="max-w-98 p-6 w-11/12 bg-white rounded-[10px] backdrop:bg-modalBackdropColor"
      open={open}
      onClose={close}
    >
      <audio ref={audioRef} src={experience.audio_url} onTimeUpdate={onTimeUpdate} onPlay={onPlay} />
      <header className="flex mb-4">
        <Link to={`${ROUTE_PATH.PEER_DETAILS}?listenerId=${experience.listener_role_id}`}>
          <Avatar
            variant="rounderSmall"
            image={experience.profile_photo_square_file_url}
            available={experience.is_available}
          />
        </Link>
        <div className="flex grow justify-between">
          <Link
            to={`${ROUTE_PATH.PEER_DETAILS}?listenerId=${experience.listener_role_id}`}
            className="flex flex-col gap-y-2 ml-6"
          >
            <div className="text-[#222833] text-xl font-extrabold font-['Texta'] leading-snug">{experience.name}</div>
            {experience.is_listener && (
              <div className="flex gap-2">
                <div className="rounded-full w-5 h-5 bg-[#2ee5da] flex justify-center items-center">
                  <PhoneIcon color="black" width={11} />
                </div>
                <div className="text-sm font-texta antialiased leading-normal">Listener</div>
              </div>
            )}
          </Link>
          <button className="h-6" onClick={close}>
            <ExitIcon width={26} height={26} color="black" />
          </button>
        </div>
      </header>
      <div className="text-[#222833] text-base font-semibold leading-[21px] mb-4">{experience.excerpt}</div>
      <div className="flex flex-nowrap gap-x-4 items-center justify-between my-8">
        <div className="w-10 h-10 rounded-full bg-gray-50 border-gray-100 left-5 inline-flex">
          <button
            data-testid="play-experience"
            className="cursor-pointer m-auto"
            onClick={() => {
              analytics.trackEvent(EventTypes.PLAY_EXPERIENCE, experience.id);
              paused ? onPlay() : audioRef.current?.pause();
            }}
          >
            {paused ? <PlayIcon /> : <PauseIcon />}
          </button>
        </div>
        <div
          style={{
            width: '100%',
            background: `linear-gradient(to right, #AFFF54 ${percent.toString()}%, #F6F6F6 0%)`,
            height: '4px',
          }}
        />
        <div className="flex gap-x-2 cursor-pointer">
          <ThumbsUpIcon onClick={() => handleRatingClick(1)} isFill={experience.rating > 0} />
          <ThumbsDownIcon onClick={() => handleRatingClick(-1)} isFill={experience.rating < 0} />
        </div>
      </div>
      <Link to={`${ROUTE_PATH.PEER_DETAILS}?listenerId=${experience.listener_role_id}`}>
        <Button className="w-full mb-2" variant="primary">
          Meet {experience.name}
        </Button>
      </Link>
      {experience.is_listener && (
        <Button onClick={() => handleScheduleModalOpen(experience)} className="w-full mb-2" variant="secondary">
          Schedule a call
        </Button>
      )}
    </Dialog>
  );
};
