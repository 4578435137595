import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_PATH } from '../../routes/route-paths';
import { Avatar, Button } from '@kindlyhuman/component-library';
import { JaniceAvatar } from '../../assets';
import { ActiveCall, Call, CallRequest, useEndCallMutation, useRequestCancelMutation } from '../../hooks/useCalls';
import { useUser } from '../../hooks/useUser';
import moment from 'moment';
import { CALL_UNITS_CALL_DEFAULT_LENGTH } from '../scheduling-modal/scheduling-modal';
import { PostCallListItem, useUpdatePostCall } from '../../hooks/usePostCall';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

interface CallCardWrapperProps extends Omit<CallCardProps, 'variant'> {
  variant: CallCardProps['variant'];
}

export const CallCardWrapper: React.FC<CallCardWrapperProps> = ({ variant, ...props }) => {
  const callInEarlyFlag = useFeatureFlag('CALL_IN_EARLY');
  const variantProps: Record<
    CallCardProps['variant'],
    { title: string; subtitle: string; buttonText?: string; buttonSubtitle?: string; button?: React.ReactNode }
  > = {
    missed: {
      title: 'Missed Call',
      subtitle: 'This call has ended',
    },
    unavailable: {
      title: 'Unavailable',
      subtitle: 'Your Listener is currently unavailable',
      buttonText: 'Reschedule',
      buttonSubtitle: 'Schedule another time to talk with your Listener',
    },
    upcoming: {
      title: 'Your Upcoming Call',
      subtitle: 'This call is starting soon',
      buttonText: `Call Now: ${process.env.REACT_APP_TWILIO_PHONE_NUMBER}`,
      buttonSubtitle: props.isNowCall
        ? 'Click to join or rejoin the call now'
        : callInEarlyFlag.enabled
          ? 'You can join the call up to 5 minutes before the start time'
          : `We will call you when your call has started`,
    },
    scheduled: {
      title: 'Scheduled Call',
      subtitle: 'You have an upcoming call',
      buttonText: 'Reschedule',
      buttonSubtitle: 'Schedule another time to talk with your Listener',
    },
    active: {
      title: 'Your Active Call',
      subtitle: 'This call is currently active',
      buttonText: `Call Now: ${process.env.REACT_APP_TWILIO_PHONE_NUMBER}`,
      buttonSubtitle: 'Click to join or rejoin the call now',
    },
    pending: {
      title: 'Call Pending',
      subtitle: 'Waiting for confirmation from your Listener',
    },
    completed: {
      title: 'Completed Call',
      subtitle: 'This Call Has Ended',
      buttonText: `Let's Recap!`,
      buttonSubtitle: 'Tell us about your call',
    },
    backup: {
      title: 'Backup Listener',
      subtitle: `You're listener is unavailable, but we've found a backup for you`,
      buttonText: 'Yes, Connect Me',
      buttonSubtitle: 'Confirm to connect with the backup listener',
    },
  };

  const cardProps = variantProps[variant] || {};

  return <CallCard variant={variant} {...cardProps} {...props} />;
};

interface CallCardProps {
  title?: string;
  subtitle?: string;
  buttonText?: string;
  buttonSubtitle?: string;
  buttonClick?: () => void;
  fiveMinutesOut?: boolean;
  isNowCall?: boolean;
  variant: 'missed' | 'upcoming' | 'scheduled' | 'active' | 'pending' | 'completed' | 'unavailable' | 'backup';
  callRequest?: CallRequest;
  activeCall?: ActiveCall;
  postCall?: PostCallListItem;
}

export const CallCard: React.FC<CallCardProps> = ({
  title,
  subtitle,
  buttonText,
  buttonSubtitle,
  buttonClick,
  fiveMinutesOut = false,
  isNowCall = false,
  variant,
  callRequest,
  activeCall,
  postCall,
}) => {
  const callInEarlyFlag = useFeatureFlag('CALL_IN_EARLY');
  const { data: user } = useUser();
  const cancelMutation = useRequestCancelMutation();
  const postCallMutation = useUpdatePostCall();
  const endCallMutation = useEndCallMutation();
  const [cancelModal, setCancelModal] = React.useState(false);

  const callItem = callRequest?.connections[0] || activeCall || postCall?.call;

  // Listener Info
  const imageUrl = callItem?.listener_role.profile_photo_square_file_url;
  const listenerName = callItem?.listener_role.user.display_name;

  // User balance info
  const isCallUnits = user?.caller_role.is_call_units;
  const remainingBalance = isCallUnits
    ? user?.caller_role.payment_data.available_minutes / CALL_UNITS_CALL_DEFAULT_LENGTH
    : user?.caller_role.payment_data.available_minutes;

  // Time & Date Info
  const userTimeZone = user?.timezone ? user.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone;

  let startTimeText = '';
  let dateText = '';
  const scheduledAt = callRequest?.connections[0].scheduled_at
    ? moment.utc(callRequest.connections[0].scheduled_at).tz(userTimeZone)
    : null;
  let startTime =
    scheduledAt?.format('hh:mm A') || (callItem?.created_at && moment(callItem.created_at).format('hh:mm A'));
  callInEarlyFlag.enabled && scheduledAt?.add(5, 'minutes');
  const date = scheduledAt?.format('dddd, MMMM DD') || moment(callItem?.created_at).format('dddd, MMMM DD');
  const isUpcoming = scheduledAt?.isAfter(moment());
  startTimeText =
    variant === 'pending'
      ? 'Starting Soon'
      : variant === 'unavailable'
        ? ''
        : isUpcoming
          ? `Starting @ ${startTime}`
          : `Started @ ${startTime}`;
  dateText = date;

  // Determine if we show the cancel button for active calls
  const now = new Date();
  const createdAt = new Date(activeCall?.request.connections[0].scheduled_at || activeCall?.created_at!);
  const fiveMinutesAfterCreatedAt = new Date(createdAt.getTime() + 5 * 60000);
  const canTerminateCall = now >= fiveMinutesAfterCreatedAt;

  // Determine cancel/dismiss button behavior
  const displayCancel =
    !['active', 'upcoming'].includes(variant) ||
    (variant === 'active' && canTerminateCall) ||
    (!isNowCall && variant == 'upcoming') ||
    (variant == 'upcoming' && isNowCall && canTerminateCall);
  const cancelOrDismiss = ['missed', 'completed', 'unavailable'].includes(variant) ? 'Dismiss' : 'Cancel Call';

  const handleCancel = () => {
    setCancelModal(true);
  };

  const handleConfirmCancel = () => {
    setCancelModal(false);
    if (variant === 'completed') {
      postCallMutation.mutate({ postCallId: postCall!.id, payload: {} });
    } else if (variant === 'active' || (variant === 'upcoming' && isNowCall)) {
      endCallMutation.mutate(activeCall!.id);
    } else {
      cancelMutation.mutate(callRequest!.id);
    }
  };

  return (
    <div
      data-testid={`${variant}-call-card`}
      className={`h-full w-90 min-w-90 bg-[#2ee5da] bg-opacity-40 rounded-[10px] flex flex-col relative select-none`}
    >
      <div className="flex px-3 pt-2 justify-between">
        <div className="flex flex-col">
          <div className="text-2xl font-textaBlack leading-normal">{title}</div>
          {subtitle && <div className="text-sm font-thin opacity-70 font-texta leading-normal">{subtitle}</div>}
        </div>
      </div>
      <div className={`flex flex-col px-3 py-2 gap-2`}>
        <div className="flex flex-col gap-4">
          <CallCardTile
            image={imageUrl!}
            name={listenerName!}
            startTime={startTimeText}
            date={dateText}
            callUnits={isCallUnits!}
            remainingBalance={remainingBalance!}
          />
          {buttonText && buttonSubtitle && (
            <div className="flex flex-col gap-4">
              <Button
                variant="secondary"
                className={`w-full text-[#2ee5da] bg-[#081d40] ${!isNowCall && (!fiveMinutesOut || !callInEarlyFlag.enabled) && variant == 'upcoming' ? 'bg-opacity-30 border-none' : 'hover:bg-[#081d40] hover:bg-opacity-70'}`}
                onClick={buttonClick}
                disabled={!isNowCall && (!fiveMinutesOut || !callInEarlyFlag.enabled) && variant == 'upcoming'}
              >
                {!isNowCall && (!fiveMinutesOut || !callInEarlyFlag.enabled) && variant == 'upcoming'
                  ? 'Call Starting Soon'
                  : buttonText}
              </Button>
              <div className="text-[14px] text-center font-texta antialiased">{buttonSubtitle}</div>
            </div>
          )}
        </div>
        <div className={`flex ${displayCancel ? 'justify-between' : 'justify-end'} py-2`}>
          {displayCancel && (
            <div
              className="text-sm font-normal antialiased font-texta leading-normal px-3 py-0.5 rounded-full border border-black cursor-pointer"
              onClick={handleCancel}
            >
              {cancelOrDismiss}
            </div>
          )}

          <div>
            <Link
              to={ROUTE_PATH.SUPPORT}
              className="text-sm font-normal antialiased font-texta leading-normal px-3 py-0.5 rounded-full border border-black cursor-pointer"
            >
              Support
            </Link>
          </div>
        </div>
      </div>
      {cancelModal && (
        <ConfirmCancelModal
          onClose={() => setCancelModal(false)}
          onConfirm={handleConfirmCancel}
          cancelOrDismiss={cancelOrDismiss}
        />
      )}
    </div>
  );
};

interface CallCardTileProps {
  image: string;
  name: string;
  startTime: string;
  date: string;
  callUnits: boolean;
  remainingBalance: number;
}

export const CallCardTile: React.FC<CallCardTileProps> = ({
  image,
  name,
  startTime,
  date,
  callUnits,
  remainingBalance,
}) => {
  return (
    <>
      <div className="bg-white rounded-[10px] flex gap-2 select-none">
        <Avatar variant="rounder" image={image} className="m-3" />
        <div className="flex flex-col py-2">
          <div className="text-3xl text-[#240089] font-textaBlack antialiased leading-tight">{name}</div>
          <div className="text-xl font-textaBlack antialiased leading-tight">{startTime}</div>
          <div className="text-sm font-texta">{date}</div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <div className="text-[14px] font-texta antialiased">Available {callUnits ? 'Call' : 'Minutes'} Balance</div>
          <div className="text-[14px] font-textaBlack">
            {remainingBalance} {callUnits ? 'Calls' : 'Minutes'}
          </div>
        </div>
      </div>
    </>
  );
};

interface ConfirmCancelModalProps {
  onClose: () => void;
  onConfirm: () => void;
  cancelOrDismiss: string;
}

export const ConfirmCancelModal: React.FC<ConfirmCancelModalProps> = ({ onClose, onConfirm, cancelOrDismiss }) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50 mx-auto px-2">
      <div className="bg-white rounded-[10px] flex flex-col gap-4 p-4">
        <div className="text-2xl font-textaBlack antialiased">
          Are you sure you want to {cancelOrDismiss.split(' ')[0].toLocaleLowerCase()} this call?
        </div>
        <div className="flex justify-center gap-4">
          <Button variant="primary" onClick={onConfirm}>
            Yes
          </Button>
          <Button variant="primary" onClick={onClose}>
            No
          </Button>
        </div>
      </div>
    </div>
  );
};
