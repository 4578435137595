import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useResourceByID } from '../../hooks/useResources';
import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { Spinner } from '../../components/common/spinner';
import { Button } from '@kindlyhuman/component-library';
import { useUser } from '../../hooks/useUser';
import { TagPill } from '../../components/mwa-3.5-redesign/tag-pill';
import NotFound404 from '../not-found/not-found-404';
import { ROUTE_PATH } from '../../routes/route-paths';
import { PartnerResourceConfig } from './partner-resource-config';

export const ResourceDetailPage = () => {
  const navigate = useHistory();
  const { pathname } = useLocation();

  const resourceID = useMemo(() => parseInt(pathname.match(/\/resource\/(\d+)_.*/)?.[1] ?? ''), [pathname]);
  const { data: user } = useUser();
  const { data: resource, isLoading: isResourceLoading } = useResourceByID(resourceID);
  const client = user?.caller_role.active_subscription?.package?.client;

  const bodyComponent = useMemo(() => {
    if (isResourceLoading) {
      return <Spinner />;
    }
    if (!resource) {
      return <NotFound404 />;
    }
    return (
      <ResourceDetailView
        phoneNumber={resource.phone_number}
        name={resource.name}
        url={resource.url}
        urlDescription={resource.url_description}
        ctaButtonText="Visit Online"
        imageUrl={resource.image_url}
        tagNames={resource.tag_names}
        longDescription={resource.long_description}
        clientLogoUrl={resource.client_id ? client?.comms_logo_file_url : undefined}
      />
    );
  }, [isResourceLoading, resource, navigate]);

  return (
    <div className="md:bg-neutral-700 md:bg-opacity-5 h-screen md:pb-8 flex flex-col">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      {bodyComponent}
    </div>
  );
};

interface ResourceDetailProps {
  name: string;
  url?: string;
  urlDescription: string;
  ctaButtonText: string;
  imageUrl: string;
  tagNames?: string[] | null;
  description?: string;
  longDescription?: string | JSX.Element;
  clientLogoUrl?: string;
  ctaButtonDisabled?: boolean;
  ctaButtonContent?: JSX.Element;
  partnerResourceConfig?: PartnerResourceConfig;
  ssoLinkUrlPending?: boolean;
  phoneNumber?: string;
  callButtonText?: string;
}

export const ResourceDetailView: React.FC<ResourceDetailProps> = ({
  name,
  url,
  urlDescription,
  ctaButtonText,
  imageUrl,
  tagNames,
  longDescription,
  clientLogoUrl,
  ctaButtonDisabled,
  partnerResourceConfig,
  ssoLinkUrlPending,
  phoneNumber,
  callButtonText = 'Call',
}) => {
  const desktop = useMediaQuery('md');
  const navigate = useHistory();

  const buttonClassNameOverride =
    'grow w-full text-[#2ee5da] hover:bg-[#081d40] hover:bg-opacity-70 bg-[#081d40] border-[#240089]';

  const externalProviderCtaContent = (
    partnerResourceConfig: PartnerResourceConfig,
    providerName: string,
    ssoLinkUrlPending?: boolean,
    ssoLinkUrl?: string,
  ) => {
    if (partnerResourceConfig.needsAddress) {
      return (
        <div className="flex flex-col items-center md:items-end">
          <div className="text-gray-800 text-sm font-medium leading-tight">
            We need your home address in order to activate this service.
          </div>
          <div className="mt-3">
            <Button
              slim
              variant="secondary"
              onClick={() => {
                navigate.push(ROUTE_PATH.PROFILE);
              }}
            >
              Add Address
            </Button>
          </div>
        </div>
      );
    }

    if (partnerResourceConfig.waitForProcessingDate) {
      return (
        <div className="text-gray-800 text-base font-medium leading-tight">
          You will have access to {name} beginning on {partnerResourceConfig.processingDateString}
        </div>
      );
    }

    return (
      <>
        <a className="w-full" href={ssoLinkUrl} target="_blank" rel="noopener noreferrer">
          <Button className={buttonClassNameOverride} variant="primary" disabled={ssoLinkUrlPending}>
            {ssoLinkUrlPending ? 'Loading...' : ctaButtonText}
          </Button>
        </a>
        {providerName === 'recuro' && (
          <a className="w-full" href="tel:8556732876">
            <Button className={buttonClassNameOverride} variant="primary">
              Schedule Visit by Phone
            </Button>
          </a>
        )}
      </>
    );
  };

  // this handles relative resource URLs like `google.com`
  const validPath = url?.includes('.')
    ? url.startsWith('http://') || url.startsWith('https://')
      ? url
      : `https://${url}`
    : url;

  const defaultCtaContent = (
    <>
      {' '}
      {url && (
        <a className="w-full" href={validPath} target="_blank" rel="noreferrer" aria-label={urlDescription}>
          <Button disabled={ctaButtonDisabled} className={buttonClassNameOverride} variant="primary">
            {ctaButtonText}
          </Button>
        </a>
      )}
    </>
  );

  return desktop ? (
    <div className="h-full flex flex-col relative bg-white gap-3">
      {/* header chunk */}
      <div className="bg-[#005eff]/10">
        <div className="w-full p-4 flex flex-row max-w-7xl mx-auto">
          <img className="w-[154px] h-[154px] rounded-lg" src={imageUrl} />
          <div className="flex flex-col mx-4 gap-2">
            <div className="text-[#240089] text-2xl font-extrabold font-['Manrope'] leading-7">{name}</div>
            {tagNames && (
              <div className="flex flex-row flex-wrap gap-2">
                {tagNames.map((tagName) => (
                  <TagPill key={tagName} name={tagName} className="w-fit bg-[#25008a] text-white" />
                ))}
              </div>
            )}
          </div>
          <div className="flex flex-col pt-2 pr-4 ml-auto">
            <div className="w-full pt-8 flex flex-row justify-center items-center">
              {partnerResourceConfig
                ? externalProviderCtaContent(partnerResourceConfig, name, ssoLinkUrlPending, url)
                : defaultCtaContent}
            </div>
            {phoneNumber && (
              <a href={`tel:${phoneNumber}`}>
                <Button className="w-full mt-2" variant="secondary">
                  {callButtonText}
                </Button>
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row max-w-7xl mx-auto">
        {/* part of my plan chunk */}
        {clientLogoUrl && (
          <div className="w-1/3 flex flex-row text-center text-[#222833] text-base font-bold font-['Manrope'] leading-[21px]">
            <img className="w-[98px] h-[49px]" src={clientLogoUrl} />
            <div className="py-3 px-4">Part of My Plan</div>
          </div>
        )}
        {/* description chunk */}
        {longDescription && (
          <div className="w-2/3 ml-auto py-5 pr-5 text-[#222833] text-base font-medium font-['Manrope'] leading-[21px]">
            {longDescription}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="px-4 flex flex-col relative bg-white gap-3">
      {/* first chunk */}
      <div className="w-full flex flex-row my-4">
        <img className="w-[154px] h-[154px] rounded-lg" src={imageUrl} />
        <div className="flex flex-col mx-4 gap-2">
          <div className=" text-[#240089] text-2xl font-extrabold font-['Manrope'] leading-7">{name}</div>
          {tagNames && (
            <div className="flex flex-row flex-wrap gap-2">
              {tagNames.map((tagName) => (
                <TagPill key={tagName} name={tagName} className="w-fit bg-[#25008a] text-white" />
              ))}
            </div>
          )}
        </div>
      </div>
      {/* visit online chunk */}
      <div className="w-full py-5 flex flex-col justify-center items-center">
        {partnerResourceConfig
          ? externalProviderCtaContent(partnerResourceConfig, name, ssoLinkUrlPending, url)
          : defaultCtaContent}
        {phoneNumber && (
          <a className="w-full" href={`tel:${phoneNumber}`}>
            <Button className="w-full mt-2" variant="secondary">
              {callButtonText}
            </Button>
          </a>
        )}
      </div>
      {/* part of my plan chunk */}
      {clientLogoUrl && (
        <div className="flex flex-row text-center text-[#222833] text-base font-bold font-['Manrope'] leading-[21px]">
          <img className="w-[98px] h-[49px]" src={clientLogoUrl} />
          <div className="my-auto px-4">Part of My Plan</div>
        </div>
      )}
      {/* description chunk */}
      {longDescription && (
        <div className="w-[331px] py-5 text-[#222833] text-base font-medium font-['Manrope'] leading-[21px]">
          {longDescription}
        </div>
      )}
    </div>
  );
};
