export const ROUTE_PATH = {
  LANDING_PAGE: '/',
  WELCOME_PAGE: '/welcome',
  PWA_REDIRECT_PAGE: '/pwa-redirect',
  ACCOUNT_SETTINGS: '/settings',
  CHANGE_PASSWORD: '/change-password',
  GROUP_Id: '/group-id',
  ONBOARDING: '/onboarding',
  SELECT_PLAN: '/select-plan',
  ONBOARDING_PAYMENT: '/onboarding-payment',
  SIGNUP: '/signup',
  AUTHENTICATE: '/authenticate',
  HOME: '/home',
  MENU: '/menu',
  CALLER: '/caller',
  PEER_DETAILS: '/peer-details',
  MEMBERSHIP_MANAGEMENT: '/manage',
  MY_TOPICS: '/my-topics',
  PAYMENT: '/payment',
  LISTENERS: '/listeners',
  MY_CONNECTIONS: '/my-connections',
  RECOMMENDED_PEERS: '/recommended-peers',
  LISTENER_FILTER: '/listener-filter',
  PROFILE: '/profile',
  PROFILE_STEP: '/profile-step',
  MY_PREFERENCES: '/my-preferences',
  MY_PROGRAM: '/my-plan',
  RESOURCES: '/resources',
  RESOURCE: '/resource',
  SUPPORT: '/support',
  HELP: '/help',
  ABOUT: '/about',
  MY_REWARDS: '/my-rewards',
  REFLECTION_HISTORY: '/morphii-history',
  CALL_SCHEDULE: '/call-schedule',
  VERIFICATION: '/resend-link',
  LOGIN: '/login',
  SHARED_LISTENER: '/shared-listener',
  RESET_PASSWORD: '/reset-password',
  TERMS_AND_SERVICE: '/terms',
  PRIVACY_POLICY: '/privacy-policy',
  LIBRARY: '/library',
  RETURN: '/return',
  SSO_ERROR: '/ssoerror',
  PROTOTYPE: '/prototype',
  COUNSELING: '/counseling',
  TELEMED: '/telemed',
  URGENT: '/urgent',
  CRISIS_LITE: '/crisis-lite',
  PRESCRIPTION: '/prescription-benefit',
  PEDIATRIC: '/pediatric',
  DEPENDENTS: '/dependents',
  EmailVerification: '/email-verification',
  POSTCALLSEQUENCE: '/post-call',
  PLAN: '/plan',
  FAQS: '/faqs',
  CALL_HISTORY: '/call-history',
  404: '/404',
  AVAILABLE_LISTENERS: '/available-listeners',
};
