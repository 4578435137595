import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_PATH } from '../../routes/route-paths';

interface ResourceTileProps {
  providerPath?: string;
  image?: string;
  resourceName?: string;
  caption?: string;
  tagNames?: string[];
  skeleton?: boolean;
  id?: number;
  horizontal?: boolean;
}

export const ResourceTile: React.FC<ResourceTileProps> = ({
  providerPath,
  image,
  resourceName,
  caption,
  tagNames,
  id,
  horizontal,
}) => {
  return (
    <Link
      key={`resource-${id || resourceName}`}
      to={providerPath || `${ROUTE_PATH.RESOURCE}/${id}_${resourceName?.replaceAll(' ', '-')}`}
    >
      <div
        className={`bg-white rounded-[10px] flex gap-2 p-1 ${horizontal && 'h-44 min-w-[336px]'} select-none cursor-pointer`}
      >
        <div className="w-28 h-28 p-2 overflow-hidden">
          <img src={image} alt="Resource Picture" className="rounded-md" />
        </div>
        <div className="flex flex-col py-2 gap-2">
          <div
            data-testid={``}
            className={`w-48 text-xl font-textaBlack antialiased leading-normal ${horizontal && 'text-nowrap overflow-hidden truncate'}`}
          >
            {resourceName}
          </div>
          <div
            className={`w-48 text-xs font-manrope antialiased leading-normal text-[#666666] ${horizontal && 'line-clamp-3 overflow-hidden text-wrap truncate'}`}
          >
            {caption}
          </div>
          <div className="flex gap-x-2 gap-y-2 flex-wrap">
            {tagNames &&
              tagNames.map((tagName) => {
                return (
                  <div className="bg-[#25008a] rounded-md h-5 flex w-fit items-center text-center text-white text-[10px] font-thin font-manrope leading-[18px] px-2">
                    {tagName}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Link>
  );
};

export const ResourceTileSkeleton: React.FC<{ horizontal?: boolean }> = ({ horizontal }) => {
  return (
    <div className={`bg-gray-300 rounded-[10px] flex gap-2 animate-pulse p-1 ${horizontal && 'h-36 min-w-[336px]'}`}>
      <div className="w-24 h-24 m-2 bg-gray-400 rounded flex-shrink-0" />
      <div className="flex flex-col py-3 gap-2 w-full">
        <div className="h-6 bg-gray-400 rounded w-3/4" />
        <div className="h-4 bg-gray-400 rounded w-1/2" />
        <div className="h-4 bg-gray-400 rounded w-1/2" />
      </div>
    </div>
  );
};
