import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_PATH } from '../../routes/route-paths';
import { Avatar, Button, ExitIcon, ExitIconWithBorder } from '@kindlyhuman/component-library';
import { useQueryClient } from '@tanstack/react-query';
import { useRecommendedPeers } from '../../hooks/usePeers';
import { useReflectionMutation, useReflections } from '../../hooks/useReflection';
import { MorphiiResult, MorphiiWidget } from '../morphii/morphii-widget';
import { morphiiContext } from '../../app';
import Toast from '../common/PopUpMessage';
import { User, useUser } from '../../hooks/useUser';
import { useLockBody } from '../../hooks/useLockBody';
import { twMerge } from 'tailwind-merge';
import { ModalBackdrop } from '../common/ModalBackdrop';

type ReflectionProps = {
  open: boolean;
  handleReflectionModalDismiss: () => void;
};

const ReflectionsModal: React.FunctionComponent<ReflectionProps> = ({
  handleReflectionModalDismiss,
  open,
}): JSX.Element => {
  useLockBody(open);
  const [morphiiData, setMorphiiData] = useState<MorphiiResult>();

  const recommendedPeers = useRecommendedPeers();
  const { data: currentReflection } = useReflections();
  const reflectionMutation = useReflectionMutation();
  const queryClient = useQueryClient();

  const partName = morphiiData?.reaction_record.morphii.part_name;
  const intensity = morphiiData?.reaction_record.morphii.intensity;

  return (
    <>
      <ModalBackdrop onExit={handleReflectionModalDismiss} />
      <div
        className="
          fixed bottom-0 rounded-t-r-15 overflow-y-auto  bg-[#E6E6E6] h-5/6 z-40 w-full space-y-2
          md:bg-white md:max-w-screen-md md:rounded-lg md:h-[max-content]
          md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2 md:space-y-0
        "
      >
        <Header handleReflectionModalDismiss={handleReflectionModalDismiss} />
        <div className="bg-white overflow-auto">
          <div className="p-5 max-w-100 mx-auto md:max-w-full">
            {morphiiData ? (
              <>
                <RewardsCard />
                <div className="mt-10">
                  {morphiiData && partName === 'disappointed' && intensity! >= 0.3 && <Negative />}
                  {morphiiData && partName === 'delighted' && intensity! >= 0.3 && <Positive />}
                  {morphiiData && intensity! < 0.3 && <Neutral />}
                </div>
              </>
            ) : (
              <>
                <div className="flex justify-start items-center gap-4">
                  {recommendedPeers.data?.data?.[0]?.profile_photo_url_square && (
                    <Avatar
                      variant="x-small"
                      image={recommendedPeers.data?.data?.[0]?.profile_photo_url_square}
                      available={recommendedPeers.data?.data?.[0]?.is_available}
                      isPeerListener
                    />
                  )}
                  <p className="text-center text-gray-800 text-base">
                    {recommendedPeers?.data?.data?.[0]?.display_name}
                  </p>
                </div>
                <ReflectionCard className="mt-5" />
              </>
            )}

            <div className="mt-24 flex justify-center items-center md:mt-8">
              {morphiiData ? (
                <Button variant="primary" onClick={handleReflectionModalDismiss}>
                  Close
                </Button>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => {
                    queryClient.removeQueries(['reflections']);
                    morphiiContext.submit((errors: any, results: MorphiiResult[]) => {
                      setMorphiiData(results?.[0]);
                      if (!currentReflection?.id) {
                        return;
                      }
                      if (errors) {
                        if (errors[0].error?.message === 'Morphii intensity required.') {
                          Toast.error('Morphii intensity required.');
                        } else {
                          Toast.error('Something went wrong. If this problem persists please contact help.');
                        }
                      } else {
                        reflectionMutation.mutate(
                          {
                            reflectionId: currentReflection?.id,
                            engagementScore: results?.[0].reaction_record.morphii.intensity,
                          },
                          {
                            onError: (err) => {
                              // @ts-ignore
                              Toast.error(err.response.data.description);
                            },
                          },
                        );
                      }
                    });
                  }}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Positive: React.FunctionComponent = (): JSX.Element => {
  return <></>;
};

const Neutral: React.FunctionComponent<{ className?: string }> = ({ className }): JSX.Element => {
  const history = useHistory();
  return (
    <div className={twMerge('', className)}>
      <p className="text-gray-800 text-lg font-bold text-center">We hear you. You should know you are not alone.</p>
      <Button
        className="block mx-auto mt-3"
        variant="secondary"
        onClick={() => {
          history.push(ROUTE_PATH.AVAILABLE_LISTENERS);
        }}
      >
        Explore experiences
      </Button>
    </div>
  );
};

const Negative: React.FunctionComponent<{ className?: string }> = ({ className }): JSX.Element => {
  const history = useHistory();
  return (
    <div className={twMerge('', className)}>
      <p className="text-gray-800 text-lg font-bold text-center">Maybe it’s a good time to connect with someone.</p>
      <Button
        className="block mx-auto mt-3"
        variant="secondary"
        onClick={() => {
          history.push(ROUTE_PATH.AVAILABLE_LISTENERS);
        }}
      >
        Explore Available Peers
      </Button>
    </div>
  );
};

const validateRewards = (user?: User) => {
  if (!user) {
    return;
  }
  const products = user?.caller_role?.active_subscription?.package?.package_products;
  const checkIn = products?.some((item: any) => item.product.product_type === 'check_in');
  return checkIn ? products?.some((item: any) => item.product.product_type === 'reward') : checkIn;
};

const RewardsCard: React.FunctionComponent = (): JSX.Element => {
  const history = useHistory();
  const { data: user } = useUser();
  const packageProduct = user?.caller_role?.active_subscription?.package?.package_products?.find(
    (item: any) => item?.product?.key === 'CHECKIN_WITH_INTERIM',
  );
  const engagementPoints = packageProduct?.configuration?.engagement_points || 0;
  const hasRewards = validateRewards(user);
  if (!hasRewards) {
    return <></>;
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-5">
        <div className="px-4 grow shrink basis-0 text-gray-800 text-lg font-bold leading-normal text-center">
          Thanks for taking the time to make time for yourself today.
        </div>
        <div className="px-4 grow shrink basis-0 text-gray-800 leading-normal font-normal text-center">
          You earned{' '}
          <span className="font-bold">
            +{engagementPoints} {engagementPoints > 1 ? 'points' : 'point'}
          </span>{' '}
          for your reflection!
        </div>
        <div>
          <Button
            variant="secondary"
            onClick={() => {
              history.push(ROUTE_PATH.MY_REWARDS);
            }}
          >
            See Rewards
          </Button>
        </div>
      </div>
    </>
  );
};

const Header: React.FC<{ handleReflectionModalDismiss: () => void }> = ({ handleReflectionModalDismiss }) => (
  <div
    className="
      bg-white p-4 flex justify-between items-center
      md:p-6 md:border-b md:border-neutral-200
    "
  >
    <div className="text-gray-800 text-xl font-bold">Reflection</div>
    <button onClick={handleReflectionModalDismiss}>
      <ExitIconWithBorder className="md:hidden" />
      <ExitIcon className="hidden md:block" color="#22282F" />
    </button>
  </div>
);

const ReflectionCard = ({ className }: { className?: string }) => {
  const [hasMorphii, setHasMorphii] = useState(true);

  return (
    <div className={twMerge('text-gray-800', className)}>
      <p className="text-lg font-bold">How are you feeling today?</p>
      <p>Take a moment to reflect and be honest with yourself - what is your mood right now?</p>
      <div className="mt-12 flex justify-center">
        <MorphiiWidget hasMorphii={hasMorphii} setHasMorphii={setHasMorphii} />
      </div>
      <p className="pt-5 text-lg font-bold">Earn Points!</p>
      <p>
        Earn <span className="font-bold">+1 point</span> for letting us know how you are feeling today!
      </p>
    </div>
  );
};

export default ReflectionsModal;
