import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@kindlyhuman/component-library';

import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import ProfileForm from '../../components/profile_form';
import { Spinner } from '../../components/common';
import { UnsavedChangesModal } from '../../components/UnsavedChangesModal';

// User Profile
const ProfilePage = (): JSX.Element => {
  const navigate = useHistory();

  const [saveChangesModal, setSaveChangesModal] = useState(false);

  return (
    <ProfileForm
      className="w-full h-full bg-kindlyGray"
      withLoader
      loaderComponent={<Spinner />}
      topActionButton={({ isDirty }) => (
        <>
          <MobileMenuHeader
            className="w-screen"
            onBack={() => {
              isDirty ? setSaveChangesModal(true) : navigate.goBack();
            }}
          />
          <DesktopMenuHeader className="md:!-mt-0" />
        </>
      )}
      bottomActionButton={({ isDirty, isSubmitting, isValid }) => (
        <>
          <div className="pt-20" />
          <div className="fixed bottom-0 left-0 right-0 py-4 bg-white border-t border-neutral-200">
            <div className="flex items-center justify-center">
              <Button
                data-testid="save-button"
                variant="primary"
                type="submit"
                disabled={!isDirty || isSubmitting}
                loading={isSubmitting}
              >
                Save Changes
              </Button>
            </div>
          </div>
          <UnsavedChangesModal
            open={saveChangesModal}
            onClose={() => {
              setSaveChangesModal(false);
            }}
            onDiscard={() => navigate.goBack()}
            savingInProgress={isSubmitting}
          />
        </>
      )}
      onSuccessSubmitted={() => {
        if (saveChangesModal) {
          setSaveChangesModal(false);
          navigate.goBack();
        }
      }}
    />
  );
};

export default ProfilePage;
